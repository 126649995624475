import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { ToastrService } from 'ngx-toastr';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private http: HttpWrapperService
  ) { }

  presentSpinner() {
    this.spinner.show();
  }

  dismissSpinner() {
    this.spinner.hide();
  }

  showSuccessToastMsg(title?: string, message?: string) {
    this.toast.success(message, title, {
      timeOut: 2000
    });
  }

  showErrorToastMsg(title?: string, message?: string) {
    this.toast.error(message, title, {
      timeOut: 3000
    });
  }

  matchConfirmPassword(formGroup: FormGroup) {
    const password = formGroup.get('newPassword').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    let returnAble = password === confirmPassword ? null : { notSame: true };

    return returnAble
  }

  // getDashboardData(period: string) {
  //   let httpInputData = new HttpInputData();
  //   let httpParams = new HttpParams();
  //   if (period) {
  //     httpParams = httpParams.set('period', period);
  //   }
  //   httpInputData.params = httpParams;
  //   return new Promise((resolve, reject) => {
  //     this.http.get(Endpoints.dashboard, httpInputData).subscribe(
  //       (res) => {
  //         resolve(res);
  //       },
  //       (error) => {
  //         if (error && error.error && error.error.message) {
  //           // this.showErrorToastMsg('', error.error.message);
  //         } else {
  //           // this.showErrorToastMsg('', 'Something went wrong. Please try again later.');
  //         }
  //         reject(error);
  //       }
  //     )
  //   });
  // }
  calculateEntries(list: Array<any>, current_page: number, limit: number, totalResult: number): Entries {
    let totalPages = 0;
    if (list.length === totalResult) {
      let pages = {
        1: []
      };
      let arr = [];
      let page = 1;
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i]);
        if (arr.length == limit) {
          pages[page] = arr;
          page += 1;
          arr = [];
        } else {
          if (i == list.length - 1) {
            pages[page] = arr;
            page += 1;
            arr = [];
          }
        }
      }
      totalPages = Object.keys(pages).length;
      list = pages[current_page] || [];
    }
    return {
      from: limit * (current_page - 1) + 0 + 1,
      to: limit * (current_page - 1) + (list.length - 1) + 1,
      total: totalResult,
      totalPages: totalPages
    };
  }

}

export interface Entries {
  from: any;
  to: any;
  total: any;
  totalPages: number;
}