export const endpoints = {
  login: "login",
  partnerList: (page: number, limit: number, statrtDate: string, endDate: string, searchpartner) => { return `getbusinesspartner?page=${page}&limit=${limit}` + (statrtDate ? `&startDate=${statrtDate}` : '') + (endDate ? `&endDate=${endDate}` : '') + (searchpartner ? `&search=${searchpartner}` : '') },
  addbusinesspartner: "addbusinesspartner",
  deletepartner: (partner_id: string) => { return `deletebusinessparnter/${partner_id}` },
  updatePartner: (partner_id: string) => { return `updatebusinesspartner/${partner_id}` },
  changePartnerStatus: (partner_id: string) => { return `bp-active-inactive/${partner_id}` },
  partnerExport: "get-business-partner-export",
  partnerBulkdataUpload: "bulk-business-partner-data",
  partnerDetails:(partner_id:string,search:string)=>{return `businesspartner/${partner_id}`+(search ? `?search=${search}`:'')},
  updateimgbusinesspartner:(businesspartner_id:string)=>{return `updateimgbusinesspartner/${businesspartner_id}`},

  //----------------------------------------Clients EndPoints------------------------------//  

  clientList: (page: number, limit: number, statrtDate: string, endDate: string, searchClient: string, filter: string) => { return `client?page=${page}&limit=${limit}` + (statrtDate ? `&startDate=${statrtDate}` : '') + (endDate ? `&endDate=${endDate}` : '') + (searchClient ? `&search=${searchClient}` : '') + (filter ? `&filter=${filter}` : '') },
  addClient: "client",
  updateClient: (client_id) => { return `client/${client_id}` },
  deleteClient: (client_id) => { return `client/${client_id}` },
  detailsClient: (client_id) => { return `client/${client_id}` },
  clientBulkData: "bulk-client-data",
  clientExport: (page: number, limit: number) => { return `client?page=${page}&limit=${limit}` },
  clientStatus: (client_id: string) => { return `client-active-inactive/${client_id}` },
  //------------------------------Administrator-------------------------//
  administratorsList: (page: number, limit, search: string) => { return `getadmin?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') },
  addAdministrator: "registeradmin",
  editAdministrator: (admin_id: string) => { return `updateadmin/${admin_id}` },
  deleteAdministrator: (admin_id: string) => { return `administrators/${admin_id}` },
  changePassword: (admin_id: string) => { return `change-password/${admin_id}` },


  //-------------------------------Broker --------------------------------//
  addBroker: "broker",
  brokerList: "broker",
  deleteBroker: (broker_id) => { return `broker/${broker_id}` },
  businessFranchise: "get-business-fanchise-export",

  //------------------------------------Client Report -------------------------//
  uploadClientReport: "client-report",

  // ------------------------------------ Upload Report -----------------------
  uploadBPincentiveReports: "upload-bp-incentive",
  uploadClient: "client-report",

  // -----------------------------BP Payment management --------------- //
  bpPaymentList: "get-bp-payment-details",
  markBpPayment: (id: string) => { return `update-payment-mark/${id}` },
  deleteBpIncentive:(client_id:string,uploaded_month:string,uploaded_year :string)=>{return `deletebusinessparnterpayment/${client_id}/${uploaded_month}/${uploaded_year}`},


  // ---------------------------Client Exception Report ----------------------//
  clientExceptionReportList: "client-exception-report",
  clientExceptionRemark: (id: string) => { return `client-exception-remark/${id}` },
  clientExceptionDelete: (id: string) => { return `exception/${id}` },
  clientUpdateuploadStatus: (id: string) => { return `exception/${id}` },
  deleteClientReportRecord:"delete-report",

  // ------------------------------ Dashboard -----------------------//
  dashboardPartnerData: (startDate: string, endDate: string) => { return `business-graph?` + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') },
  dashboardClientData: (startDate: string, endDate: string) => { return `client-graph?` + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') },
  //--------------------------------Business Franchise----------------------------//
  businessFranchiseList: (page:number,limit:number,startDate: string, endDate: string,search:string) => { return `getbusinessfranchise?page=${page}&limit=${limit}` + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') + (search ?`&search=${search}` :'') },
  addBusinessFranchise:"addbusinessfranchise",
  updateBusinessFranchise:(businessFranchise_id:string)=>{return `updatebusinessfranchise/${businessFranchise_id}`},
  // updateimgbusinessfranchise
  updateimgbusinessfranchise:(businessFranchise_id:string)=>{return `updateimgbusinessfranchise/${businessFranchise_id}`},

  deleteBusinessFranchise:(businessFranchise_id:string)=>{return  `deletebusinessfanchise/${businessFranchise_id}`},
  chnagebusinessFranchiseStatus:(businessFranchise_id:string)=>{return `franchise-active-inactive/${businessFranchise_id}`},
  uploadBuilkBusinessFranchise:"bulk-business-fanchise-data",
  bsFBulkdataUpload: "bulk-business-fanchise-data",
  businessFranchiseDetails:(updateBusinessFranchise_id:string,search:string)=>{return `singlebusinessfranchise?_id=${updateBusinessFranchise_id}` +(search ? `&search=${search}`:'')}


  

}