import { Injectable } from '@angular/core';
import * as fs from 'file-saver';
import * as Excel from 'exceljs/dist/exceljs.min.js'
@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  userFormatDownlaod(userFormatheadata) {

    //Title, Header & Data
    const header = userFormatheadata.headers;
    // const data = userFormatheadata.headers;

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8289AA' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });

    Object.keys(header).forEach((element, index) => {
      worksheet.getColumn(index + 1).width = 25;
    });



    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'User Format' + '.xlsx');
    })
  }

  userExport(excelData) {

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook2 = new Excel.Workbook();
    let worksheet = workbook2.addWorksheet('Sales Datas');


    //Add Row and formatting
    worksheet.mergeCells('B1', 'F2');
    let titleRow = worksheet.getCell('B1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }
    //Blank Row
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8289AA' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(Object.values(d));

      // let sales = row.getCell(25);
      let color = 'FF99FF99';
      // if (+sales.value < 0) {
      //   color = 'FF9999'
      // }

      // sales.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: color }
      // }
      // sales.font = {
      //   bold: true,
      //   // color: { argb: 'FFFFFF' },
      //   size: 12
      // }
    }
    );
    Object.keys(data[0]).forEach((element, index) => {
     if (element === "S,No") {
        worksheet.getColumn(index + 1).width = 15;
        data.forEach((elm, index) => {
          worksheet.getCell(`${'A'}` + `${index + 5}`).value = index + 1
          worksheet.getCell(`${'A'}` + `${index + 5}`).alignment = { horizontal: 'center' };
        })

      } else {
        worksheet.getColumn(index + 1).width = 25;

      }
    });

    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ']);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook2.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }

  partnerExport(excelData) {

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook2 = new Excel.Workbook();
    let worksheet = workbook2.addWorksheet('Sales Datas');


    //Add Row and formatting
    worksheet.mergeCells('B1', 'F2');
    let titleRow = worksheet.getCell('B1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }
    //Blank Row
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8289AA' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(Object.values(d));

      // let sales = row.getCell(25);
      let color = 'FF99FF99';
      // if (+sales.value < 0) {
      //   color = 'FF9999'
      // }

      // sales.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: color }
      // }
      // sales.font = {
      //   bold: true,
      //   // color: { argb: 'FFFFFF' },
      //   size: 12
      // }
    }
    );
    Object.keys(data[0]).forEach((element, index) => {
      if (element === "S,No") {
        worksheet.getColumn(index + 1).width = 15;
        data.forEach((elm, index) => {
          worksheet.getCell(`${'A'}` + `${index + 5}`).value = index + 1
          worksheet.getCell(`${'A'}` + `${index + 5}`).alignment = { horizontal: 'center' };
        })

      } else {
        worksheet.getColumn(index + 1).width = 25;

      }
    });

    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ']);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook2.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }

  partnerFormatExport(userFormatheadata) {

    //Title, Header & Data
    const header = userFormatheadata.headers;
    // const data = userFormatheadata.headers;

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8289AA' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });

    Object.keys(header).forEach((element, index) => {
      worksheet.getColumn(index + 1).width = 25;
    });



    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Partner Format' + '.xlsx');
    })
  }
  businessfranchiseExportFormat(userFormatheadata) {

    //Title, Header & Data
    const header = userFormatheadata.headers;
    // const data = userFormatheadata.headers;

    //Create a workbook with a worksheet
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8289AA' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });

    Object.keys(header).forEach((element, index) => {
      worksheet.getColumn(index + 1).width = 25;
    });



    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Business Franchise Format' + '.xlsx');
    })
  }

  businessFranchiseExport(excelData){

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook2 = new Excel.Workbook();
    let worksheet = workbook2.addWorksheet('Sales Datas');


    //Add Row and formatting
    worksheet.mergeCells('B1', 'F2');
    let titleRow = worksheet.getCell('B1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }
    //Blank Row
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8289AA' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(Object.values(d));

      // let sales = row.getCell(25);
      let color = 'FF99FF99';
      // if (+sales.value < 0) {
      //   color = 'FF9999'
      // }

      // sales.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: color }
      // }
      // sales.font = {
      //   bold: true,
      //   // color: { argb: 'FFFFFF' },
      //   size: 12
      // }
    }
    );
    Object.keys(data[0]).forEach((element, index) => {
     if (element === "S,No") {
        worksheet.getColumn(index + 1).width = 10;
        data.forEach((elm, index) => {
          worksheet.getCell(`${'A'}` + `${index + 5}`).value = index + 1
          worksheet.getCell(`${'A'}` + `${index + 5}`).alignment = { horizontal: 'center' };
        })

      } else {
        worksheet.getColumn(index + 1).width = 25;

      }
    });

    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ']);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook2.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }
}
