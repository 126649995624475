import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
// import { AdminModule } from './components/admin/admin.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ModalModule } from './modal/modal.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { RoundProgressModule } from 'ngx-round-progress';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FileService } from './services/excel_file/file.service';
import { InterceptorService } from './services/http/interceptor/interceptor.service';
import { NgxImageCompressService } from 'ngx-image-compress';


@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // AdminModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    NgApexchartsModule,
    ModalModule,
    AngularEditorModule,
    NgSelectModule,
    NgbModule,
    RoundProgressModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    NgxPaginationModule


  ],
  providers: [DatePipe, FileService,NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
