import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from '../apienspoints/endpoint';
import { CommonService } from '../common/common.service';
import { HttpInputData, HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';
import { StorageService } from '../storage/storage.service';
import { BpPaymentListApiPayload } from './home.model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private httpWrapperService: HttpWrapperService,
    private storage: StorageService,
    private commonService: CommonService
    ) { }

  login(payload: any) {

    return this.httpWrapperService.post(endpoints.login, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  getPartnet(page, limit, startdate, endDate, search) {
    return this.httpWrapperService.get(endpoints.partnerList(page, limit, startdate, endDate, search)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  addpartner(payload: any) {

    return this.httpWrapperService.post(endpoints.addbusinesspartner, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

 

  getBusinessFranchise() {
    return this.httpWrapperService.get(endpoints.businessFranchise).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  updatePartner(payload: any, partner_id) {

    return this.httpWrapperService.put(endpoints.updatePartner(partner_id), payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }
  updateimgBusinesspartner(businesspartne_id,payload:FormData){
    return this.httpWrapperService.put(endpoints.updateimgbusinesspartner(businesspartne_id),payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }
  
  detailsOfPartner(partner_id,search){
    return this.httpWrapperService.get(endpoints.partnerDetails(partner_id,search)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }


  changePartnerStatus(payload,partner_id) {

    return this.httpWrapperService.put(endpoints.changePartnerStatus(partner_id),payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }
  deletepartner(partner_id) {

    return this.httpWrapperService.delete(endpoints.deletepartner(partner_id)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  exportPartner() {
    return this.httpWrapperService.get(endpoints.partnerExport).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  uploadBuilData(payload: FormData) {

    return this.httpWrapperService.post(endpoints.partnerBulkdataUpload, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }
  //-----------------------------Clients--------------------------------//

  getClient(page, limit, startDate, endDate, searcClient, filter) {
    return this.httpWrapperService.get(endpoints.clientList(page, limit, startDate, endDate, searcClient, filter)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  addClient(payload: any) {

    return this.httpWrapperService.post(endpoints.addClient, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  updateClient(payload: any, client_id) {

    return this.httpWrapperService.put(endpoints.updateClient(client_id), payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  deleteClient(client_id) {

    return this.httpWrapperService.delete(endpoints.deleteClient(client_id)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  changeClientStatus(payload: any, client_id) {

    return this.httpWrapperService.put(endpoints.clientStatus(client_id), payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  clientDetails(client_id) {
    return this.httpWrapperService.get(endpoints.detailsClient(client_id)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  builkDataUpload(payload: any) {
    return this.httpWrapperService.post(endpoints.clientBulkData, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  clientExport(page, limit) {
    return this.httpWrapperService.get(endpoints.clientExport(page, limit)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }








  //--------------------------------------Admin-tr-----------------------------//
  administrator(page, limit, search) {
    return this.httpWrapperService.get(endpoints.administratorsList(page, limit, search)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }


  addAdministrator(payload: any) {
    return this.httpWrapperService.post(endpoints.addAdministrator, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  updateAdministrator(payload: any, client_id) {

    return this.httpWrapperService.put(endpoints.editAdministrator(client_id), payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  deleteAdministrator(client_id) {

    return this.httpWrapperService.delete(endpoints.deleteAdministrator(client_id)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }
  updateAdministratorPassword(payload: any, admin_id) {

    return this.httpWrapperService.put(endpoints.changePassword(admin_id), payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }


  //--------------------------Broker---------//
  addBroker(payload: any) {

    return this.httpWrapperService.post(endpoints.addBroker, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  brokerList() {
    return this.httpWrapperService.get(endpoints.brokerList).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }
  deletbroker(broker_id) {

    return this.httpWrapperService.delete(endpoints.deleteBroker(broker_id)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  //------------------------------Client report Upload-----------------------//
  uploadClientReport(payload: FormData) {

    return this.httpWrapperService.post(endpoints.uploadClientReport, payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  // ---------------------------------- BP Payment Management --------------------------- //
  getBpPaymentList(payload: BpPaymentListApiPayload) {
    return new Promise((resolve, reject) => {
      this.httpWrapperService.post(endpoints.bpPaymentList, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  markBpPayment(id: string, value: boolean) {
    return new Promise((resolve, reject) => {
      this.httpWrapperService.put(endpoints.markBpPayment(id), {'mark_payment':value ? 1 : 0}).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          this.commonService.showErrorToastMsg(this.httpWrapperService.handleError(error));
          reject(error);
        }
      );
    });
  }

  deleteBPIncentive(client_id:string,uploaded_month:string,uploaded_year :string){
        return new Promise((resolve, reject) => {
      this.httpWrapperService.delete(endpoints.deleteBpIncentive(client_id,uploaded_month,uploaded_year)).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          this.commonService.showErrorToastMsg(this.httpWrapperService.handleError(error));
          reject(error);
        }
      );
    });
  }
  

  // getDashboardData(query: { period?:string,fromdate?: string; todate?: string; }) {
  //   let httpInput = new HttpInputData();
  //   let httpParams = new HttpParams();
  //   if(query) {
  //     Object.keys(query).forEach(
  //       (key) => {
  //         if(query[key]) {
  //           httpParams = httpParams.set(key, query[key]);
  //         }
  //       }
  //     );
  //   }
  //   httpInput.params = httpParams;
  //   return new Promise((resolve, reject) => {
  //     this.httpWrapperService.post(endpoints.dashboard(query), query).subscribe(
  //       (res) => {
  //         resolve(res);
  //       },
  //       (error) => {
  //         // this.commonService.showErrorToastMsg(this.httpWrapperService.handleError(error));
  //         reject(error);
  //       }
  //     );
  //   });
  // }

  getDashboardPartnerData(startDate,endDate){
    return this.httpWrapperService.get(endpoints.dashboardPartnerData(startDate,endDate)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }

  getDashboardClientData(startDate,endDate){
    return this.httpWrapperService.get(endpoints.dashboardClientData(startDate,endDate)).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  }


  clientUpdateUploadStatus(payload: any, client_id) {

    return this.httpWrapperService.put(endpoints.clientUpdateuploadStatus(client_id), payload).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            // this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        return error;
        // this.commonService.handleError(error);
      });
  };


    //--------------------------------Business Franchise----------------------------//
    businessfranchiseList(page:number,limit:number,startDate:string,endDate:string,search:string) {
      return this.httpWrapperService.get(endpoints.businessFranchiseList(page,limit,startDate,endDate,search)).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    }

    updateBusinessfranchise(businessFranchise_id,payload:any) {
    
      return this.httpWrapperService.put(endpoints.updateBusinessFranchise(businessFranchise_id),payload).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    }

    updateImgbusinessfranchise(businessFranchise_id,payload:FormData) {
    
      return this.httpWrapperService.put(endpoints.updateimgbusinessfranchise(businessFranchise_id),payload).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    }
    addBusinessfranchise(payload:any) {
    
      return this.httpWrapperService.post(endpoints.addBusinessFranchise,payload).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    }

    deleteBusinessFranchise(businessFranchise_id) {

      return this.httpWrapperService.delete(endpoints.deleteBusinessFranchise(businessFranchise_id)).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    }

    changetatusBusinessFranchise(payload: any, businessFranchise_id) {

      return this.httpWrapperService.put(endpoints.chnagebusinessFranchiseStatus(businessFranchise_id), payload).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    };

    uploadBuilDataBsF(payload: FormData) {

      return this.httpWrapperService.post(endpoints.bsFBulkdataUpload, payload).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    }

    businessFranchiseDetails(businessFranchise_id,search){
      return this.httpWrapperService.get(endpoints.businessFranchiseDetails(businessFranchise_id,search)).map(
        (res: any) => {
          if (res) {
            if (res.status == 200) {
              // this.handleLogin(res);
            }
            return res;
          }
        }
      ).toPromise()
        .catch((error) => {
          return error;
          // this.commonService.handleError(error);
        });
    }

    
}


