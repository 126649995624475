import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { HomeService } from 'src/app/services/home_service/home.service';
import { storageKeys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  password: boolean;
  loginForm: FormGroup;
  submitted = false;
  adminData: any;

  // private commonService:CommonService
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private homeService: HomeService,
    private storage: StorageService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      "userName": ['', Validators.required],
      "password": ['', Validators.required]
    })
  }
  showPassword() {
    this.password = !this.password;
  }

  get a() {
    return this.loginForm.controls;

  }
  submit() {

    this.commonService.presentSpinner();
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.commonService.dismissSpinner();
      return;
    }
    let object = {
      "email": this.loginForm.value.userName.toLowerCase(),
      "password": this.loginForm.value.password
    }

    this.homeService.login(object).then((res) => {
      if (res.code == 200) {
        this.commonService.showSuccessToastMsg(res.message);
        this.adminData = res;
        console.log('res--login',this.adminData)
        this.storage.set(storageKeys.AdminData, res.data);
        this.storage.set(storageKeys.authtoken, res.data.jwt_token[0]);
        localStorage.setItem("data", JSON.stringify(res.data));



        this.router.navigate(['/csgpl/dashboard'])
      } else {

        this.commonService.showErrorToastMsg(res.error.message);
      }
    }).finally(() => {
      this.commonService.dismissSpinner();

    })

  }

}
